import 'gatsby-theme-apollo-core/src/styles.less';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

export default function Layout(props) {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={(data) => {
        const { title, description } = data.site.siteMetadata;
        return (
          <Fragment>
            <Helmet defaultTitle={title} titleTemplate={`%s - ${title}`}>
              <meta name="description" content={description} />
              {/* See: https://github.com/closeio/close-ui/blob/master/webpack/templates/_branding.tpl.html */}
              <meta
                name="apple-mobile-web-app-status-bar-style"
                content="black"
              />
              <link rel="icon" href="/favicon.ico" sizes="32x32" />
              <link rel="icon" href="/icon.svg" type="image/svg+xml" />
              <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
              <link
                rel="manifest"
                href="/manifest.webmanifest"
                crossOrigin="use-credentials"
              />
            </Helmet>
            {props.children}
          </Fragment>
        );
      }}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
