/**
 * Most of this code is taken from
 * https://github.com/apollographql/gatsby-theme-apollo/blob/7b317d3ab458a49cf93041c05f3ca115eb4f94cd/packages/gatsby-theme-apollo-docs/src/components/page-content.js
 * The main difference is that we remove unnecessary edit on github and
 * comment on forum links and we don't render an aside heading if no
 * subnav items can be found on a page.
 * See: https://github.com/closeio/developer.close.com/issues/243 for more
 * details.
 */
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import SectionNav from 'gatsby-theme-apollo-docs/src/components/section-nav';
import cn from 'classnames';
import styled from '@emotion/styled';
import useMount from 'react-use/lib/useMount';
import { PageNav, breakpoints, colors } from 'gatsby-theme-apollo-core';
import { withPrefix } from 'gatsby';

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
});

const InnerWrapper = styled.div({
  flexGrow: 1,
  width: 0,
  '.api-ref': {
    h4: {
      code: {
        fontSize: '1.1em',
      },
    },
    '*:not(pre) > code[class*="language-"]': {
      padding: 0,
      background: 'none',
    },
  },
});

const BodyContent = styled.div({
  // style all anchors with an href and no prior classes
  // this helps avoid anchors with names and styled buttons
  'a[href]:not([class])': {
    color: colors.primary,
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
    code: {
      color: 'inherit',
    },
  },
  [['h1', 'h2', 'h3', 'h4', 'h5', 'h6']]: {
    code: {
      whiteSpace: 'normal',
    },
    a: {
      color: 'inherit',
      textDecoration: 'none',
      ':hover': {
        color: colors.text2,
      },
    },
  },
  '*:not(style) +': {
    [['h2', 'h3', 'h4', 'h5']]: {
      marginTop: 64,
    },
  },
  img: {
    display: 'block',
    maxWidth: '100%',
  },
  '.mermaid svg': {
    maxWidth: '100%',
  },
});

const Aside = styled.aside({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  width: 240,
  maxHeight: `calc(100vh - 72px)`,
  marginTop: -36,
  padding: '40px 0',
  marginLeft: 40,
  position: 'sticky',
  top: '72px',
  [breakpoints.lg]: {
    display: 'none',
  },
  [breakpoints.md]: {
    display: 'block',
  },
  [breakpoints.sm]: {
    display: 'none',
  },
});

const AsideHeading = styled.h4({
  fontWeight: 600,
});

export default function PageContent(props) {
  const contentRef = useRef(null);
  const [imagesToLoad, setImagesToLoad] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(0);

  useMount(() => {
    if (props.hash) {
      // turn numbers at the beginning of the hash to unicode
      // see https://stackoverflow.com/a/20306237/8190832
      const hash = props.hash.toLowerCase().replace(/^#(\d)/, '#\\3$1 ');
      try {
        const hashElement = contentRef.current.querySelector(hash);
        if (hashElement) {
          hashElement.scrollIntoView();
        }
      } catch (error) {
        // let errors pass
      }
    }

    let toLoad = 0;
    const images = contentRef.current.querySelectorAll('img');
    images.forEach((image) => {
      if (!image.complete) {
        image.addEventListener('load', handleImageLoad);
        toLoad++;
      }
    });

    setImagesToLoad(toLoad);
  });

  function handleImageLoad() {
    setImagesLoaded((prevImagesLoaded) => prevImagesLoaded + 1);
  }

  const pageIndex = props.pages.findIndex((page) => {
    const prefixedPath = withPrefix(page.path);
    return (
      prefixedPath === props.pathname ||
      prefixedPath.replace(/\/$/, '') === props.pathname
    );
  });

  return (
    <Wrapper>
      <InnerWrapper>
        <BodyContent
          ref={contentRef}
          className={cn('content-wrapper', {
            'api-ref': props.apiReference,
          })}
        >
          {props.children}
        </BodyContent>
        <PageNav
          prevPage={props.pages[pageIndex - 1]}
          nextPage={props.pages[pageIndex + 1]}
        />
      </InnerWrapper>
      {props.headings.length > 0 && (
        <>
          <Aside>
            <AsideHeading>{props.title}</AsideHeading>
            <SectionNav
              headings={props.headings}
              contentRef={contentRef}
              imagesLoaded={imagesLoaded === imagesToLoad}
            />
          </Aside>
        </>
      )}
    </Wrapper>
  );
}

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  hash: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  apiReference: PropTypes.bool.isRequired,
  headings: PropTypes.array.isRequired,
};
