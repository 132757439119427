import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as LogoIcon } from '../../images/logo.svg';
import { ReactComponent as DocsIcon } from '../../images/docs.svg';

const Wrapper = styled.div({
  display: 'flex',
  fontSize: 24,
});

const StyledLogoIcon = styled(LogoIcon)({
  height: '1em',
  marginRight: '0.5em',
});

const StyledDocsIcon = styled(DocsIcon)({
  height: '0.8em',
  marginTop: '0.1em',
});

export default function Logo() {
  return (
    <Wrapper>
      <StyledLogoIcon />
      <StyledDocsIcon />
    </Wrapper>
  );
}
