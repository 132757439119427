import React from 'react';
import styled from '@emotion/styled';
import { IconProceed } from '@apollo/space-kit/icons/IconProceed';
import { breakpoints, colors } from 'gatsby-theme-apollo-core';

const Container = styled.div({
  display: 'flex',
  flexShrink: 0,
  width: 280,
  justifyContent: 'space-between',
  [breakpoints.md]: {
    display: 'none',
  },
});

const StyledLink = styled.a({
  display: 'flex',
  alignItems: 'center',
  color: colors.primary,
  lineHeight: 2,
  textDecoration: 'none',
  ':hover': {
    color: colors.text3,
  },
});

const StyledIcon = styled(IconProceed)({
  height: '0.75em',
  marginLeft: '0.5em',
});

export default function HeaderQuickLinks() {
  return (
    <Container>
      <StyledLink href="mailto:support@close.com">Email Support</StyledLink>
      <StyledLink href="tel:+18334625673">Call Us </StyledLink>
      <StyledLink href="https://app.close.com/">
        Go to Close
        <StyledIcon weight="thin" />
      </StyledLink>
    </Container>
  );
}
