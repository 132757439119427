/* eslint-disable no-undef */
import './src/global/index.css';

export const onRouteUpdate = () => {
  // Hack for nested categories in sidebar -- inset any items that begin with
  // a bullet in the sidebar_title frontmatter.
  // In cases where a page can't load, document.querySelector can be null
  const sidebarQuerySelector = document.querySelector('.sidebar');
  if (sidebarQuerySelector) {
    Array.from(sidebarQuerySelector.querySelectorAll('li > a')).forEach(
      (anchor) => {
        if (anchor.innerText.startsWith('•')) {
          anchor.style.marginLeft = '8px';
          anchor.innerText = anchor.innerText.substring(1);
        }
      },
    );
  }
};
