import React from 'react';
import { SEO } from 'gatsby-theme-apollo-core';

export default function CustomSEO({ ...props }) {
  return (
    <SEO
      {...props}
      favicon="https://close.com/static/img/favicon.ico?h=e9674dd5"
    />
  );
}
