// Most styles were borrowed from gatsby-theme-apollo-docs/src/components/search
import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useKey from 'react-use/lib/useKey';
import styled from '@emotion/styled';
import { breakpoints, colors, smallCaps } from 'gatsby-theme-apollo-core';
import { TextField } from '@apollo/space-kit/TextField';
import { useCombobox } from 'downshift';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';

const borderRadius = 5;
const border = `1px solid ${colors.text3}`;

const Hotkey = styled.div({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  border,
  borderColor: colors.text4,
  color: colors.text4,
  borderRadius,
  textAlign: 'center',
  lineHeight: 1.125,
  right: 10,
  pointerEvents: 'none',
  width: 24,
  height: 24,
});
const Container = styled.div({
  flexGrow: 1,
  marginRight: 40,
  color: colors.text2,
  position: 'relative',
  zIndex: 1,
  [breakpoints.md]: {
    marginRight: 0,
  },
});

const Overlay = styled.div(
  ({ visible }) =>
    !visible && {
      opacity: 0,
      visibility: 'hidden',
    },
  {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `rgba(${hexToRgb(colors.text2)}, 0.5)`,
    transitionProperty: 'opacity, visibility',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-in-out',
    zIndex: 1,
  },
);

const Input = styled.input(
  ({ resultsShown }) =>
    resultsShown && { boxShadow: `rgba(0, 0, 0, 0.1) 0 2px 12px` },
  {
    fontSize: 16,
  },
);

const Menu = styled.div({
  position: 'absolute',
  width: '100%',
  maxWidth: '100%',
  minWidth: 'auto',
  marginTop: 14,
  borderRadius,
  boxShadow: `rgba(0, 0, 0, 0.1) 0 2px 12px`,
  backgroundColor: '#fff',
  zIndex: 2,
});

const Item = styled.div(
  ({ isHighlighted }) =>
    isHighlighted && { backgroundColor: colors.primaryLight },
  {
    cursor: 'pointer',
    padding: '16px 20px',
    '&:first-of-type': {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    },
    '&:last-of-type': {
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  },
);

const ResultLink = styled(Link)({
  display: 'flex',
  flexDirection: 'column',
  color: colors.text2,
  textDecoration: 'none',
});

const ResultHeader = styled.h4({
  marginTop: 0,
  marginBottom: 4,
  borderBottom: 0,
  fontSize: 14,
  color: 'inherit',
  ...smallCaps,
});

const ResultBody = styled.small({
  marginTop: 8,
});

export default function SearchBox() {
  const [inputValue, setValue] = useState('');
  const inputRef = useRef();

  const data = useStaticQuery(graphql`
    query Search {
      localSearchPages {
        index
        store
      }
    }
  `);

  const { index, store } = data.localSearchPages;
  const items = useFlexSearch(inputValue, index, store);

  const onInputValueChange = useCallback((e) => setValue(e.inputValue), []);

  // FlexSearch uses fuzzy matching, and it can be confusing. So, we only want
  // to show items that actually have real text matches. In addition, we make
  // sure that resources are always prioritized ahead of other search
  // results.
  const shownItems = items
    .reduce(
      (filteredItems, item) => {
        item.foundText = findTextInAST(inputValue.trim(), item.ast, item.title);
        const bucket = item.slug.includes('resources/') ? 0 : 1;
        if (item.foundText) {
          filteredItems[bucket].push(item);
        }
        return filteredItems;
      },
      [[], []],
    )
    .flat();

  const {
    isOpen,
    reset,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    openMenu,
  } = useCombobox({
    inputValue,
    onInputValueChange,
    items: shownItems,
    onSelectedItemChange: (e) => {
      navigate(e.selectedItem?.slug);
      reset();
    },
  });

  const { ref, onKeyDown, ...inputProps } = getInputProps({
    ref: inputRef,
    onFocus: openMenu,
  });

  // focus the input when the slash key is pressed
  useKey(
    (e) => e.key === '/' && e.target.tagName.toUpperCase() !== 'INPUT',
    (e) => {
      e.preventDefault();
      inputRef.current.focus();
    },
  );

  const resultsShown = isOpen && inputValue.trim();

  return (
    <>
      <Overlay visible={resultsShown} />
      <Container {...getComboboxProps()}>
        <TextField
          type="search"
          size="large"
          inputAs={
            <Input
              ref={ref}
              resultsShown={resultsShown}
              onKeyDown={onKeyDown}
            />
          }
          placeholder="Search docs"
          {...inputProps}
        />
        {!isOpen && !inputValue && <Hotkey>/</Hotkey>}
        <Menu {...getMenuProps()}>
          {isOpen &&
            shownItems.map((item, index) => (
              <Item
                key={item.id}
                isHighlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                <ResultLink to={item.slug} aria-label="Link to the result">
                  <ResultHeader>{item.title}</ResultHeader>
                  <span>{item.description}</span>
                  <Excerpt
                    query={inputValue.trim()}
                    foundText={item.foundText}
                  />
                </ResultLink>
              </Item>
            ))}
        </Menu>
      </Container>
    </>
  );
}

/**
 * Traverse an AST looking for all of the user-facing text in a given AST.
 * The user-facing text exists in objects that have a "type" property with a
 * value of either text or inlineCode. Once we find that, the user-facing text
 * is the value of the object at that point.
 */
function traverseASTForText(obj) {
  return Object.entries(obj || {}).reduce(
    (acc, [key, value]) =>
      key === 'type' && ['text', 'inlineCode'].includes(value) && obj.value
        ? acc.concat({
            type: value,
            text: obj.value,
          })
        : typeof value === 'object'
        ? acc.concat(traverseASTForText(value))
        : acc,
    [],
  );
}

/**
 * Gets all of the user-facing text from an AST and:
 * - Combines inlineCode blocks with adjacent text blocks because the AST
 *   separates them into separate sections.
 * - Combines smaller text blocks together. We do this because inline hyperlinks
 *   are listed as separate sections in the ASTs, and we want paragraphs to be
 *   displayed correctly in search results.
 */
function getAllTextInAST(ast) {
  const textArray = traverseASTForText(ast);
  if (textArray.length === 0) {
    return [];
  }
  const methods = ['GET', 'PUT', 'POST', 'DELETE'];
  const allText = [textArray[0].text];

  // If we just saw an inlineCode block, we'll want to combine the text that
  // comes next with the text of inlineCode block because in ASTs, inlineCode
  // renders as a separate element even when it's part of a larger paragraph.
  let justSawInlineCode = textArray[0].type === 'inlineCode';
  let justSawMethodString = methods.some((word) =>
    textArray[0].text.startsWith(word),
  );

  let prevIndex = 0;
  for (let i = 1; i < textArray.length; i++) {
    const text = textArray[i].text;
    // If the current text is less than 30 characters, we want to append
    // it to the previous text in the array. We do this because hyperlinks
    // appear as their own array elements when parsing ASTs, and we want
    // inline hyperlinks to appear as inline properly in search results. 30 was
    // chosen as the cutoff, because excerpts with 30 or more characters do not
    // look like their missing something when displayed in search results.
    const isShortText = text.length < 30;
    // If the current text is a method string like "GET /api/v1/role/", we
    // want to always render it as its own line.
    const isMethodString = methods.some((word) => text.startsWith(word));

    if (
      (isShortText || (justSawInlineCode && !justSawMethodString)) &&
      !isMethodString
    ) {
      allText[prevIndex] = `${allText[prevIndex]} ${text}`;
    } else {
      allText.push(text);
      prevIndex++;
    }
    justSawInlineCode = textArray[i].type === 'inlineCode';
    justSawMethodString = methods.some((word) => text.startsWith(word));
  }
  return allText;
}

function findTextInAST(query, ast, title) {
  const lowQuery = query.toLowerCase();
  if (title.toLowerCase().includes(lowQuery)) {
    return title;
  }
  const allText = getAllTextInAST(ast);

  const foundText = allText.find((t) => t.toLowerCase().includes(lowQuery));

  return foundText;
}

function Excerpt({ query, foundText }) {
  const lowQuery = query.toLowerCase();
  const lowFoundText = foundText.toLowerCase();

  const textPosition = lowFoundText.indexOf(lowQuery);
  let beforeQueryString = foundText.substring(0, textPosition).trimStart();
  const qs = foundText.substring(textPosition, textPosition + query.length);
  let afterQueryString = foundText.substring(textPosition + query.length);
  // The text before our query broken up into words
  const beforeSplit = beforeQueryString.split(' ');
  // The text after our query broken up into words
  const afterSplit = afterQueryString.split(' ');

  // If the number of words before or after the query is more than this number
  // we only want to display this number of words and truncate the rest
  const numWordsBeforeTruncate = 30;
  if (beforeSplit.length > numWordsBeforeTruncate) {
    beforeQueryString = `\u2026${beforeSplit
      .slice(-1 * numWordsBeforeTruncate)
      .join(' ')} `;
  }

  if (afterSplit.length > numWordsBeforeTruncate) {
    afterQueryString = `${afterSplit
      .slice(0, numWordsBeforeTruncate)
      .join(' ')}\u2026`;
  }

  return (
    <ResultBody>
      {beforeQueryString}
      <strong>{qs}</strong>
      {afterQueryString}
    </ResultBody>
  );
}

Excerpt.propTypes = {
  query: PropTypes.string,
  ast: PropTypes.object,
};

// --
function hexToRgb(hex) {
  const [, r, g, b] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return `${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}`;
}
