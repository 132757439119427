import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as LogoIcon } from '../../images/logo-without-text.svg';

const Wrapper = styled.div({
  display: 'flex',
  fontSize: 24,
});

const StyledLogoIcon = styled(LogoIcon)({
  height: '1em',
});

const Anchor = styled.a({
  display: 'inline-flex',
});

export function MobileLogo() {
  return (
    <Wrapper>
      <Anchor href="https://close.com">
        <StyledLogoIcon />
      </Anchor>
    </Wrapper>
  );
}
