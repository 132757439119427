import React from 'react';
import SearchBox from '../../components/SearchBox';
import HeaderQuickLinks from '../../components/HeaderQuickLinks';

export default function HeaderButton() {
  return (
    <>
      <SearchBox />
      <HeaderQuickLinks />
    </>
  );
}
